.App {
  text-align: center;
}

figcaption{
  font-style: italic;
  color: #555d66;
}

.gamification-picture{
  width: 80%;
}

.container-fluid {
  background-color: #ececec;
}

.top-container{
  margin-top: 1rem !important;
}

.bottom-logo-container{
  max-height: 5rem;
}

.bottom-container{
  margin-bottom: 1rem !important;
}

.nav-link{
  color: rgba(0,0,0,0.8)!important;
}

/* .header-logo{
  width: 30%;
} */

.footer-logo {
  width: 30%;
}

.footer-text {
  font-size: smaller;
  color: #6c757d!important;
}

.footer-text-sm {
  font-size: x-small;
  color: #6c757d!important;
}

.home-header-logo{
  min-width: 5%;
  max-width: 8%;
}

.page-container{
  padding-top: 1.5rem;
}

.card{
  margin-bottom: 1rem;
}

.navbar-brand {
  width: 200px;
}

.header-logo {
  /* width:20% */
  width: 100%;
}

.bottom-shadow {
  box-shadow: 0px 1px 6px grey;
}

.mt-6 {
  margin-top: 6rem!important;
}

.text-size-s {
  font-size: 0.8rem;
}

.text-size-m {
  font-size: 1rem;
}

/* CSS para los textos dentro del slider*/
.slider-title-720 {
  position: absolute;
  top: 30%;
  height: 40%;
  left: 2rem;
  right: 2rem;
}

.slider-title-450 {
  position: absolute;
  top: 10%;
  height: 60%;
  left: 2rem;
  right: 2rem;
}

.slider-static-title {
  position: absolute;
  top: 30%;
  left: 2rem;
  height: 50%;
}

.text-title {
  color: #ececec;
  font-weight: bold;
  text-transform: uppercase;
}

.slider-text {
  position: absolute;
  left: 1rem;
  text-transform: none !important;
}

.text-description {
  color: #ececec;
  padding: 0 0.5rem 0;
}

.slider-text-container {
  background-color:rgba(0,0,0,0.7);
  padding: 0.5rem;
  border-radius: 0.5rem;
  padding-top: 2rem;
  box-shadow: 0px 0px 10px black;
}
/**/

.slider-slide {
  object-fit: cover;
  width: 50%;
  height: 50%;
}

.gvis-separator {
  background-color: #42bbec;
}

.secondary-section {
  background-color: rgba(66,187,236,1);
  padding: 2rem;
}

.third-section {
  background-color: white;
  padding: 2rem;
}

.btn-block {
  width: 100%;
}

.btn-gvis {
  color: #039fdb !important;
  border-color: #039fdb !important;
}

.btn-gvis:hover {
  background-color: #039fdb !important;
  color: #ffffff !important;
}

.btn-light2 {
  background-color: rgba(66,187,236,1) !important;
  color: #ffffff !important;
}

.btn-light2:hover {
  background-color: #ffffff !important;
  color: rgba(66,187,236,1) !important;
}

.white-text {
  color: white !important;
}

.circle {
  border-radius: 120px !important;
}

.gvis-color {
  color: #039fdb;
}

.nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.nav-link.active {
  color: #039fdb !important;
}

@media (max-width: 768px) {
  /* .slider-title{
    height: 70%;
  } */
  /* .slider-slide {
    width: 60%;
    height: 60%;
  }
  .slider-static-title {
    top: 10%;
    width: 60%;
    height: 100%;
  } */
}

@media (max-width: 320px){
  .slider-title-{
    height: 40%;
    left: 2rem;
    right: 2rem;
  }

  .slider-title-{
    height: 40%;
    left: 2rem;
    right: 2rem;
  }

  .slider-slide {
    width: 100%;
    height: 100%;
  }

  .slider-static-title {
    top: 10%;
    left: 2rem;
    right: 2rem;
    height: 60%;
  }
}

@media (min-width: 568px){
  .slider-title-720{
    width: 50%;
  }

  .slider-title-450{
    width: 50%;
  }
}